import React, { useEffect, useState } from "react";
import "../assets/styles/Navbar.css";
import { IoMenu, IoSearchOutline } from "react-icons/io5";
import { RiLoginCircleLine } from "react-icons/ri";
import { IoMdCloseCircleOutline, IoMdContact } from "react-icons/io";
import imslogo from "../assets/images/logo/imslogo.png";
import { Link } from "react-router-dom";
import { MdOutlineArrowDropDownCircle } from "react-icons/md";
import nepal from "../assets/images/flags/nepal.png";
import { FaMinus, FaPlus } from "react-icons/fa6";
import councelling from "../assets/images/modal/councelling.jpg";
import { useQuery } from "react-query";
import axios from "axios";

const Navbar = () => {
  const [megaMenu, setMegaMenu] = useState(null);
  const [selectCourse, setSelectedCourse] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isMenuVisible, setMenuVisible] = useState(true);
  const [isHovered, setHovered] = useState(false);
  const [searchModal, setSearchModal] = useState(false);
  const [isSticky, setSticky] = useState(false);

  const handleLinkClick = () => {
    setMenuVisible(false);
  };

  const handleMouseEnter = () => {
    setHovered(true);
    setMenuVisible(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const getStudyinAbroad = async () => {
    let url = process.env.REACT_APP_BASE_URL;
    let response = await axios.get(url + `country?study_in_abroad=Yes`);
    return response?.data
  };

  const { data: studyAbroad, isLoading: isloadingAbroad } = useQuery({
    queryKey: ["GetStudyInAbroad"],
    queryFn: () => getStudyinAbroad(),
  });






  const getallcourses = async () => {
    let url = process.env.REACT_APP_BASE_URL;
    let response = await axios.get(url + `courses`);

    console.log("courses", response.data)
    return response?.data;
  };

  const { data: courses, isLoading: courseload } = useQuery({
    queryKey: ["getcouses"],
    queryFn: () => getallcourses(),
  });

  // Mbbs Aborad
  const getmbbslist = async () => {
    let url = process.env.REACT_APP_BASE_URL;
    let response = await axios.get(
      url + `blog-list?category_slug=mbbs-in-abroad`
    );
    console.log(response?.data);
    return response?.data;
  };

  const { data: mbbslist, isLoading: mbbslistload } = useQuery({
    queryKey: ["getmbbslist"],
    queryFn: () => getmbbslist(),
  });

  // console.log("Mbbs Course list", mbbslist);

  return (
    <>
      <div
        className={
          isSticky ? "navigation_wrapper sticky-form" : "navigation_wrapper"
        }
      >
        <section className="top_menu">
          <div className="container">
            <div className="top_menu_wrapper roboto-regular ">
              <div></div>
              <ul className="top_menu_lists">
                <li className="live-concelling">
                  <a href="" className="pulse">
                    Live Councelling
                  </a>
                </li>
                <li>|</li>
                <li
                  onClick={() => setSearchModal(true)}
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <IoSearchOutline /> Search
                </li>
                <li>|</li>
                <li
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <RiLoginCircleLine />
                  Login
                </li>
                <li>|</li>
                <li
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <IoMdContact />
                  <Link to="/contact">    Contact</Link>
                </li>
              </ul>
            </div>
          </div>
        </section>

        <section className="main_menu">
          <div className="container">
            <div className="main_menu_wrapper">
              <div className="logo_sec">
                <img src={imslogo} alt="" width="100px" />
              </div>
              <div>
                <ul className="main_menu_lists roboto-regular">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>|</li>
                  <li
                    className="submenu"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    About <MdOutlineArrowDropDownCircle />
                    {isMenuVisible && (
                      <ul className="submenu__list">
                        <li>
                          <Link to="/about" onClick={handleLinkClick}>
                            About Us
                          </Link>
                        </li>
                        <li>
                          <Link to="/team" onClick={handleLinkClick}>
                            Our Team
                          </Link>
                        </li>
                        <li>
                          <Link to="/testimonial" onClick={handleLinkClick}>
                            Testimonial
                          </Link>
                        </li>
                        {/* <li>
                        <Link to="career">Career</Link>
                      </li> */}
                      </ul>
                    )}
                  </li>

                  <li>|</li>
                  <li>
                    <Link to="#" className="apply_btn">
                      Apply Now
                    </Link>
                  </li>
                  <li>|</li>

                  <li
                    className="submenu"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    Courses <MdOutlineArrowDropDownCircle />
                    {isMenuVisible && (
                      <ul className="submenu__list">
                        {courses?.data.map((row) => (
                          <li>
                            <Link
                              to={`/study-abroad/${row.slug}`}
                              onClick={handleLinkClick}
                            >
                              {row.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                  <li>|</li>
                  <li>
                    <Link to="/blog">Blog</Link>
                  </li>
                  <li>|</li>
                  <li>
                    <Link to="/career">Career</Link>
                  </li>
                  <li>|</li>
                  <li onClick={() => setMegaMenu("menu")}>
                    <Link to="#">
                      <IoMenu fontSize="30px" />
                    </Link>
                  </li>

                  {megaMenu === "menu" && (
                    <div className="megamenu animate__animated animate__fadeInDown">
                      <div className="container">
                        <div
                          className="close-menu"
                          onClick={() => setMegaMenu(null)}
                        >
                          <IoMdCloseCircleOutline
                            fontSize="30px"
                            color="black"
                          />
                        </div>

                        <div className="megamenu_wrapper roboto-regular">
                          <div className="dropdown_wrapper">
                            <div className="dropdown_primary">
                              <h4>About</h4>
                              <ul className="megamenu_dropdown_list">
                                <li>
                                  <Link
                                    to="/about"
                                    onClick={() => setMegaMenu(null)}
                                  >
                                    {" "}
                                    About Us
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/team"
                                    onClick={() => setMegaMenu(null)}
                                  >
                                    Our Team
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/testimonial"
                                    onClick={() => setMegaMenu(null)}
                                  >
                                    Testimonial
                                  </Link>
                                </li>

                              </ul>
                            </div>

                            <div className="dropdown_primary">
                              <h4>Services</h4>
                              <ul className="megamenu_dropdown_list">
                                <li>
                                  <Link
                                    to="/services/test-preparation"
                                    onClick={() => setMegaMenu(null)}
                                  >
                                    Test Preparation
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/services/admission-guidance"
                                    onClick={() => setMegaMenu(null)}
                                  >
                                    Admission Guidance
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/services/course-selection"
                                    onClick={() => setMegaMenu(null)}
                                  >
                                    Course Selection
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/services/career-councelling"
                                    onClick={() => setMegaMenu(null)}
                                  >
                                    Career Councelling
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/services/visa-and-travel-assistance"
                                    onClick={() => setMegaMenu(null)}
                                  >
                                    Visa and Travel Assistance
                                  </Link>
                                </li>
                              </ul>
                            </div>

                            <div className="dropdown_primary">
                              <h4>Gallery</h4>
                              <ul className="megamenu_dropdown_list">
                                <li>
                                  <Link
                                    to="/gallery/seminar"
                                    onClick={() => setMegaMenu(null)}
                                  >
                                    Seminars
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/gallery/student-departure"
                                    onClick={() => setMegaMenu(null)}
                                  >
                                    Student Departure
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/gallery/ims-office"
                                    onClick={() => setMegaMenu(null)}
                                  >
                                    IMS Offices
                                  </Link>
                                </li>
                              </ul>
                            </div>

                            <div className="dropdown_primary">
                              <h4 onClick={() => setMegaMenu(null)}>Blog</h4>
                            </div>

                            <div className="dropdown_primary">
                              <h4 onClick={() => setMegaMenu(null)}>
                                Contact Us
                              </h4>
                            </div>
                          </div>
                          <div className="dropdown_wrapper">
                            <div className="dropdown_primary">
                              <h4>MBBS in Abroad</h4>
                              <ul className="megamenu_dropdown_list">
                                {
                                  mbbslist?.data.slice(0, 8).map((row) => (
                                    <li>
                                      <img src={row?.country_image} alt="" />
                                      <Link
                                        to={`/blog/${row?.slug}`}
                                        onClick={() => setMegaMenu(null)}
                                      >
                                        {row?.title}
                                      </Link>
                                    </li>
                                  ))
                                }

                              </ul>
                            </div>
                          </div>
                          <div className="dropdown_wrapper">
                            <div className="dropdown_primary">
                              <h4>Study In Abroad</h4>

                              <ul className="megamenu_dropdown_list">
                                {studyAbroad?.data.slice(0, 8).map((row, index) => (
                                  <li>
                                    <img src={row?.image} alt="" />
                                    <Link
                                      to={`/study-abroad/${row.slug}`}
                                      onClick={() => setMegaMenu(null)}
                                    >
                                      Study In {row?.name}
                                    </Link>
                                  </li>
                                ))}
                                <li>
                                  <Link
                                    className="explore_btn"
                                    to="/study-abroad-countries"
                                    onClick={() => setMegaMenu(null)}
                                  >
                                    Explore More
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>

                          <div className="dropdown_wrapper">
                            <div className="dropdown_primary">
                              <h4>Study in India</h4>
                              <ul className="megamenu_dropdown_lists">
                                <li
                                  onClick={() =>
                                    setSelectedCourse(
                                      selectCourse === "engineering"
                                        ? null
                                        : "engineering"
                                    )
                                  }
                                >
                                  <Link>Engineering</Link>
                                  {selectCourse === "engineering" ? (
                                    <FaMinus />
                                  ) : (
                                    <FaPlus />
                                  )}
                                </li>
                                {selectCourse === "engineering" && (
                                  <ul className="subdropdown">
                                    <li onClick={() => setMegaMenu(null)}>
                                      B.E
                                    </li>
                                    <li onClick={() => setMegaMenu(null)}>
                                      M.E
                                    </li>
                                    <li onClick={() => setMegaMenu(null)}>
                                      B.Tech
                                    </li>
                                    <li onClick={() => setMegaMenu(null)}>
                                      M.Tech
                                    </li>
                                  </ul>
                                )}
                                <li
                                  onClick={() =>
                                    setSelectedCourse(
                                      selectCourse === "management"
                                        ? null
                                        : "management"
                                    )
                                  }
                                >
                                  <Link >Management</Link>
                                  {selectCourse === "management" ? (
                                    <FaMinus />
                                  ) : (
                                    <FaPlus />
                                  )}
                                </li>
                                {selectCourse === "management" && (
                                  <ul className="subdropdown">
                                    <li onClick={() => setMegaMenu(null)}>
                                      BBA
                                    </li>
                                    <li onClick={() => setMegaMenu(null)}>
                                      MBA
                                    </li>
                                  </ul>
                                )}

                                <li
                                  onClick={() =>
                                    setSelectedCourse(
                                      selectCourse === "medical"
                                        ? null
                                        : "medical"
                                    )
                                  }
                                >
                                  <Link>Medical</Link>
                                  {selectCourse === "medical" ? (
                                    <FaMinus />
                                  ) : (
                                    <FaPlus />
                                  )}
                                </li>
                                {selectCourse === "medical" && (
                                  <ul className="subdropdown">
                                    <li onClick={() => setMegaMenu(null)}>
                                      MD
                                    </li>
                                    <li onClick={() => setMegaMenu(null)}>
                                      DO
                                    </li>
                                    <li onClick={() => setMegaMenu(null)}>
                                      PharmaD
                                    </li>
                                    <li onClick={() => setMegaMenu(null)}>
                                      MBBS
                                    </li>
                                  </ul>
                                )}
                                <li
                                  onClick={() =>
                                    setSelectedCourse(
                                      selectCourse === "law" ? null : "law"
                                    )
                                  }
                                >
                                  <Link>Law</Link>
                                  {selectCourse === "law" ? (
                                    <FaMinus />
                                  ) : (
                                    <FaPlus />
                                  )}
                                </li>
                                {selectCourse === "law" && (
                                  <ul className="subdropdown">
                                    <li onClick={() => setMegaMenu(null)}>
                                      LLB
                                    </li>
                                    <li onClick={() => setMegaMenu(null)}>
                                      LLM
                                    </li>
                                  </ul>
                                )}
                              </ul>
                            </div>
                            <div className="logo_sec_menu">
                              <img src={imslogo} alt="" width="200px" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="admission_year_btn" onClick={() => setShowModal(true)}>
        <div className="admission_year_content">Admission 2025-26</div>
      </div>

      {showModal && (
        <section className="modal_wrapper" onClick={() => setShowModal(false)}>
          <div className="modal_container">
            <div className="modal_title">
              <h2>
                Admission is Open for 2024-25 <br /> Book Free Councelling
              </h2>
            </div>

            <button className="close_btn" onClick={() => setShowModal(false)}>
              <IoMdCloseCircleOutline fontSize="25px" />
            </button>
            <div className="my_modal">
              <div className="modal_thumb">
                <img src={councelling} alt="" style={{ width: "100%" }} />
              </div>
              <div className="modal_desc">
                <form action="">
                  <div className="form-content">
                    <input type="text" placeholder="Enter Full Name" />
                  </div>
                  <div className="form-content">
                    <input type="number" placeholder="Enter Whatsapp Number" />
                  </div>
                  <div className="form-content">
                    <input type="email" placeholder="Enter Email Id" />
                  </div>
                  <a className="apply_btn">Submit</a>
                </form>
              </div>
            </div>
          </div>
        </section>
      )}
      {searchModal && (
        <section
          className="modal_wrapper navbar"
          onClick={() => setSearchModal(false)}
        >
          <div className="modal_container">
            <div className="modal_title">
              <h2>Start Searching...</h2>
            </div>

            <button className="close_btn" onClick={() => setShowModal(false)}>
              <IoMdCloseCircleOutline fontSize="25px" />
            </button>
            <div className="my_modal">
              <div className="modal_desc">
                <form action="">
                  <div className="form-content">
                    <input
                      type="text"
                      placeholder="Search Program,Country and University"
                      style={{ width: "100%" }}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Navbar;
