import React from 'react'
import "../assets/styles/Career.css"
import { Helmet } from 'react-helmet'
import charity from "../assets/images/career/charity.webp"
import professional from "../assets/images/career/professional.webp"
import services from "../assets/images/career/services.webp"

const Career = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Career | Ims Education</title>
                <meta name="description" content="ims education about page" />
                <link rel="canonical" href="https://imseduglobal.com/about" />
            </Helmet>
            <section className="career_sec">
                <div className="container">
                    <div className="section_wrapper">
                        {/* <h3>Career</h3> */}
                    </div>
                </div>
            </section>

            <section className="countries_wrapper pt-70 pb-70">
                <div className="container">
                    <div className="country_section">
                        <h3 className='career_title'>Career Categories</h3>

                        <div className="career_cate_sec">
                            <div className="career_sec_cards">
                                <img src={professional} alt="" style={{ width: "100%" }} />
                                <h3 className="text-center">
                                    International Professionals</h3>
                            </div>
                            <div className="career_sec_cards">
                                <img src={services} alt="" style={{ width: "100%" }} />
                                <h3 className='text-center'>General Service</h3>
                            </div>
                            <div className="career_sec_cards">
                                <img src={charity} alt="" style={{ width: "100%" }} />
                                <h3 className="text-center">Internships & Volunteers</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Career