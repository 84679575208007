import React, { useState } from "react";
import { FaCalendar } from "react-icons/fa";
import { FaArrowRight, FaFontAwesome, FaUser } from "react-icons/fa6";
import "../assets/styles/Blog.css";
import axios from "axios";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { useDebounce } from "@uidotdev/usehooks";
import Loader from "../components/Loader";
import { BiArrowFromLeft } from "react-icons/bi";
import { Helmet } from "react-helmet";

// import moment from "moment/moment";

const Blog = () => {
  const [searchterm, setSearechTerms] = useState("");
  const debouncedSearchTerm = useDebounce(searchterm, 500);
  const blog = async (searchterm) => {
    const newurl =
      process.env.REACT_APP_BASE_URL + `blog-list?search=${searchterm}`;
    const { data } = await axios.get(newurl);
    return data?.data;
  };

  const blogcategory = async () => {
    const newurl = process.env.REACT_APP_BASE_URL + `blog-category`;
    const response = await axios.get(newurl);
    return response?.data?.data;
  };

  const { data, isLoading } = useQuery({
    queryKey: ["blog", debouncedSearchTerm],
    queryFn: () => blog(debouncedSearchTerm),
  });
  const { data: category } = useQuery({
    queryKey: ["blogcategory"],
    queryFn: () => blogcategory(),
  });
  console.log("category Detail", category);

  return (
    <>
      {isLoading ? <Loader /> : ""}
      <Helmet>
        <meta charSet="utf-8" />
        <title>Blog | Ims Education</title>
        <meta name="description" content="ims education about page" />
        <link rel="canonical" href="https://imseduglobal.com/about" />
      </Helmet>
      <section className="about_section">
        <div className="container">
          <div className="about_wrapper">
            <h3>Blogs</h3>
          </div>
        </div>
      </section>
      <section className="pb-70 pt-70">
        <div className="container ">
          <div className="cm-flex-type-3 blog-container">
            <div className="cm-wd-70 cm-mr-20">
              {data?.map((row) => (
                <div className="box-wrapper-blog">

                  <div className="blog-image">
                      <img src={row?.blog_image} alt="" />
                    </div>
                  <div className="blog-content">
                      <h3 className="font-22">
                        <Link to={`/blog/${row?.slug}`} className="text-none">
                          {row?.title}
                        </Link>
                      </h3>
                      <p style={{ marginBottom: "5px" }}>{row?.excert}</p>
                      <span style={{ marginRight: "10px" }}>
                        <FaCalendar /> Apr 7, 2024
                      </span>
                      <span>
                        <FaUser /> Ims Education
                      </span>
                    <span>Ims Education</span>
                  </div>
                </div>
              ))}
            </div>
            <div className="cm-wd-30">
              <div className="search-box">
                <input
                  type="search"
                  onChange={(e) => setSearechTerms(e.target.value)}
                  className="search-blog"
                  placeholder="Serch Blog"
                />
              </div>
              <div className="category-blog box-shadow-1">
                <h3>Category</h3>
                <ul className="list-of-blog-cate">
                  {category?.map((row) => (
                    <li>
                      <FaArrowRight />{" "}
                      <Link to={`/blog/${row?.slug}`}>{row?.catename}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Blog;
