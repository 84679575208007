import React, { useEffect, useState } from "react";
import "../assets/styles/Gallery.css";
import { useParams } from "react-router-dom";
import s1 from "../assets/images/gallery/s1.jpg";
import s2 from "../assets/images/gallery/s2.jpg";
import s3 from "../assets/images/gallery/s3.jpg";
import s4 from "../assets/images/gallery/s4.jpg";
import d1 from "../assets/images/gallery/d1.jpg";
import d2 from "../assets/images/gallery/d2.jpg";
import d3 from "../assets/images/gallery/d3.jpg";
import d4 from "../assets/images/gallery/d4.jpg";
import o1 from "../assets/images/gallery/o1.jpg";
import o2 from "../assets/images/gallery/o2.jpg";
import o3 from "../assets/images/gallery/o3.jpg";
import o4 from "../assets/images/gallery/o4.jpg";
import { FaChevronCircleLeft, FaChevronCircleRight } from "react-icons/fa";
import { IoMdCloseCircle } from "react-icons/io";
import { Helmet } from "react-helmet";

const Gallery = () => {
  const { slug } = useParams();

  const [filterCards, setFilterCards] = useState([]);
  const [sliderNumber, setSliderNumber] = useState(0);
  const [openModal, setOpenModal] = useState(false);

  const imsGallery = [
    {
      title: "seminar",
      images: [
        {
          img: s1,
        },
        {
          img: s2,
        },
        {
          img: s3,
        },
        {
          img: s4,
        },
      ],
    },
    {
      title: "student-departure",
      images: [
        {
          img: d1,
        },
        {
          img: d2,
        },
        {
          img: d3,
        },
        {
          img: d4,
        },
      ],
    },
    {
      title: "ims-office",
      images: [
        {
          img: o1,
        },
        {
          img: o2,
        },
        {
          img: o3,
        },
        {
          img: o4,
        },
      ],
    },
  ];

  const handleOpenModal = (data) => {
    setSliderNumber(data);
    setOpenModal(true);
  };

  const handleClosemodal = () => {
    setOpenModal(false);
  };

  const prevSlide = () => {
    sliderNumber === 0
      ? setSliderNumber(filterCards.length - 1)
      : setSliderNumber(sliderNumber - 1);
  };

  const nextSlide = () => {
    sliderNumber + 1 === filterCards.length
      ? setSliderNumber(0)
      : setSliderNumber(sliderNumber + 1);
  };

  useEffect(() => {
    const data = imsGallery.filter((item) => item.title === slug);

    if (data.length > 0) {
      // Check if any items match the filter
      console.log(data[0].images); // Access images of the first matching item

      setFilterCards(data[0].images);
    } else {
      console.log("No data found for the given slug");
    }
  }, [slug]);

  return (
    <>
      <Helmet>
          <meta charSet="utf-8" />
        <title>Gallery | Ims Education</title>
          <meta name="description" content="ims education about page" />
          <link rel="canonical" href="https://imseduglobal.com/about" />
      </Helmet>
      <section className="gallery_wrapper">
        <div className="container">
          <div className="team_wrap">
            <h3>{slug}</h3>
          </div>
        </div>
      </section>

      {/* cards */}
      <div className="gallery_section_wrapper pt-70 pb-70">
        <div className="container">
          {openModal && (
            <div className="sliderWrap">
              <FaChevronCircleLeft
                className="btnPrev"
                onClick={prevSlide}
                size="40px"
              />

              <FaChevronCircleRight
                className="btnNext"
                onClick={nextSlide}
                size="40px"
              />
              <IoMdCloseCircle
                className="btnClose"
                onClick={handleClosemodal}
                size="40px"
              />

              <div className="fullScreenImage">
                <img src={filterCards[sliderNumber].img} alt="" srcset="" />
              </div>
            </div>
          )}

          <div className="gallery_card_items">
            {filterCards.map((item, index) => {
              return (
                <>
                  <div className="gallery_card_item">
                    <div
                      className="card_thumb"
                      onClick={() => handleOpenModal(index)}
                    >
                      <img src={item.img} alt="" style={{ width: "100%" }} />
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Gallery;
