import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import Navbar from "./layout/Navbar";
import ResposiveNavbar from "./layout/ResposiveNavbar";
import Footer from "./layout/Footer";
import About from "./Pages/About";
import StudyAbroad from "./Pages/StudyAbroad";
import Mbbs from "./Pages/Mbbs";
import UniversityDetails from "./components/UniversityDetails";
import Team from "./Pages/Team";
import Testimonial from "./Pages/Testimonial";
import Gallery from "./Pages/Gallery";
import Services from "./Pages/Services";
import AbroadCountries from "./Pages/AbroadCountries";
import { QueryClient, QueryClientProvider } from "react-query";
import Blog from "./Pages/Blog";
import BlogDetail from "./Pages/BlogDetail";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Contact from "./Pages/Contact";
import Career from "./Pages/Career";
import VideoBg from "./layout/VideoBg";


const queryClient = new QueryClient();

function App() {
  return (

    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <ToastContainer />
        <Navbar />
        <ResposiveNavbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services/:slug" element={<Services />} />
          <Route path="/team" element={<Team />} />
          <Route path="/testimonial" element={<Testimonial />} />
          <Route path="/gallery/:slug" element={<Gallery />} />
          <Route path="/study-abroad/:countrycode" element={<StudyAbroad />} />
          <Route path="/mbbs/:id" element={<Mbbs />} />
          <Route
            path="/university/:slugunviersity"
            element={<UniversityDetails />}
          />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:slug" element={<BlogDetail />} />

          <Route path="/study-abroad-countries" element={<AbroadCountries />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/career" element={<Career />} />

        </Routes>

        <Footer />
        <VideoBg />
      </BrowserRouter>
    </QueryClientProvider>

  );
}

export default App;
