import React from 'react'
import IMS_FOOTER from "../assets/video/IMS_FOOTER.mp4"

const VideoBg = () => {
    return (
        <div className="video-container">
            {/* <div className="left-overlay"></div> */}
            <video autoPlay muted loop id="background-video">
                <source src={IMS_FOOTER} type="video/mp4" />
                Your browser does not support the video tag.
            </video>

        </div>
    )
}

export default VideoBg